<template>
  <BaseWidget density="none">
    <template #title>
      <div class="flex justify-between">
        <div class="px-4">התראות</div>
        <div
          v-if="withResizeIcon"
          class="bg-primary-200 flex justify-center items-center rounded-full w-8">
          <MaximizeIcon
            class="cursor-pointer h-3.5 w-3.5 stroke-black"
            tabindex="0"
            role="button"
            @keyup.enter="goToAppInbox"
            @click="goToAppInbox"/>
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="notifications.length">
        <div
          v-for="notification in lastThreeNotifications"
          :key="notification.id"
          class="flex p-4 border-b last:border-b-0 p-4 border-secondary-250 justify-between">
          <div class="flex flex-col gap-1">
            <div class="text-sm font-simplerBold">{{ notification.title }}</div>
            <div class="text-sm">{{ notification.content }}</div>
            <NotificationLink
              v-if="notification.metadata?.exercise"
              :metadata="notification.metadata"/>
          </div>
          <div
            class="cursor-pointer h-5 w-5 bg-secondary-250 text-secondary-900 rounded-full flex items-center justify-center"
            tabindex="0"
            role="button"
            @click.prevent="seteNotification(notification)"
            @keyup.enter="seteNotification(notification)">
            <X :size="16" :stroke-width="1" />
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col p-4">
        <span class="font-simpler text-sm p-2 pr-1 text-center">
          בקרוב יהיו פה התראות חדשות.
        </span>
        <div class="flex flex-col justify-center items-center py-4">
          <NotificationBell />
        </div>
      </div>
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { X, Maximize2 as MaximizeIcon } from 'lucide-vue-next';
import { BaseWidget } from '@nwire/amit-design-system';
import NotificationBell from '/@/assets/notificationBell.svg?component';
import { useNotificationStore } from '/@/store/notifications';
import { useRouter } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { INotification } from '/@/types/interfaces';
import NotificationLink from '/@/components/NotificationLink.vue';

defineProps({
  withResizeIcon: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const accountStore = useAccountStore();
const notificationsStore = useNotificationStore();
const goToAppInbox = () => router.push({ name: 'app-inbox' });
const notifications = computed(() => {
  return notificationsStore.getUnreadNotifications as INotification[];
});

const lastThreeNotifications = computed(() => {
  return notifications.value.slice(0, 3);
});
const seteNotification = notification => {
  notificationsStore.setCurrentNotification(notification, accountStore.user.id);
};
</script>
